var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_detail"),
        "destroy-on-close": true,
        closable: false,
        width: "820px"
      }
    },
    [
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.propSource,
            scroll: { y: 320 },
            "row-key": function(r) {
              return r.id
            }
          }
        },
        [
          _c(
            "a-table-column",
            {
              key: "invoicePrepaymentDate",
              attrs: { "data-index": "invoicePrepaymentDate" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [
                      _vm._v(_vm._s(_vm._f("date")(text)))
                    ])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_invoice_prepayment_date")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "invoicePrepaymentNo",
              attrs: { "data-index": "invoicePrepaymentNo" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_invoice_prepayment_number")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "appliedAmount",
              attrs: { "data-index": "appliedAmount" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [
                      _vm._v(_vm._s(_vm._f("currency")(text)))
                    ])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_invoice_prepayment_used_amount")))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "a-button",
        {
          key: "save",
          attrs: { slot: "footer", type: "primary" },
          on: { click: _vm.closeModal },
          slot: "footer"
        },
        [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }